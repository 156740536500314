import './App.css';
import favico512 from './assets/favicon512.png';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="landing-page">
            <img className="header_image"  src={favico512} alt="logo" />
          <h1 className="roboto-condensed-font">towards oblivion</h1>
        </div>
          <p className="dark_text"> an alternative case study </p>
      </header>
        <footer className="App-footer">
            <p>2024 - all rights reserved</p>
        </footer>
    </div>
  );
}

export default App;
